import { toastController } from "@ionic/vue";

export default {
	/*
	** This methode used to notify the user using ionic toast component
	** check https://ionicframework.com/docs/api/toast got more info
	*/
	async presentToast (message: string, duration: number | undefined, color: string) {
		const toast = await toastController
			.create({
				message: message,
				duration: typeof duration !== 'undefined' ? duration : undefined,
				color: color,
				buttons: [
					{
						side: 'end',
						text: 'Ok',
						role: 'cancel'
					}
				]
			})
		return toast.present();
	}
}