
import ImageLoader from "./ImageLoader.vue";
import TextLoader from "./TextLoader.vue";

export default {
  components: { ImageLoader, TextLoader },
  name: "PageHeader",
  setup() {
    return {};
  },
};
