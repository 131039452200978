
import { ComponentOptions, onMounted, ref, watchEffect } from "vue";

export default {
  name: "ResultImage",
  props: {
    averageTag: {
      type: Object,
      required: false,
    },
    tagCoordinates: {
      type: Object,
      required: true,
    },
    tagImage: {
      type: Object,
      required: true,
    },
  },
  components: {},
  setup(props: ComponentOptions) {
    const imageLoaded = ref(false);

    const svgActuleWidth = ref(0);
    const svgX = ref(0);
    const svgY = ref(0);
    const svgWidth = ref<number>(props.tagImage.width);
    const svgHeight = ref<number>(props.tagImage.width);

    const myTagX = ref(0);
    const myTagY = ref(0);
    const myTagWidth = ref(0);
    const myTagHeight = ref(0);

    const averageTagX = ref(0);
    const averageY = ref(0);
    const averageWidth = ref(0);
    const averageHeight = ref(0);

    onMounted(() => {
      const newImg = new Image();
      newImg.onload = () => {
        imageLoaded.value = true;
      };
      newImg.src = props.tagImage.src;

      window.onresize = () => {
        const svgContainerWidth =
          document.querySelector<HTMLDivElement>(".svg-container")?.offsetWidth;
        if (svgContainerWidth) {
          svgActuleWidth.value = svgContainerWidth;
          applyChanges();
        }
      };
    });

    watchEffect(() => {
      if (imageLoaded.value) {
        setTimeout(() => {
          const svgContainerWidth =
            document.querySelector<HTMLDivElement>(
              ".svg-container"
            )?.offsetWidth;

          if (svgContainerWidth) {
            svgActuleWidth.value = svgContainerWidth - 32;
            applyChanges();
          }
        }, 50);
      }
    });

    const applyChanges = () => {
      if (svgActuleWidth.value !== 0) {
        const factor = props.tagImage.width / svgActuleWidth.value;

        svgWidth.value = calculateValue(props.tagImage.width, factor);
        svgHeight.value = calculateValue(props.tagImage.height, factor);

        myTagX.value = calculateValue(props.tagCoordinates.left, factor);
        myTagY.value = calculateValue(props.tagCoordinates.top, factor);
        myTagWidth.value = calculateValue(props.tagCoordinates.width, factor);
        myTagHeight.value = calculateValue(props.tagCoordinates.height, factor);

        if (props.averageTag) {
          averageTagX.value = calculateValue(props.averageTag.x, factor);
          averageY.value = calculateValue(props.averageTag.y, factor);
          averageWidth.value = calculateValue(props.averageTag.width, factor);
          averageHeight.value = calculateValue(props.averageTag.height, factor);
        }
      }
    };

    const calculateValue = (size: number, factor: number) => {
      return Math.floor(size / factor);
    };

    return {
      svgX,
      svgY,
      svgWidth,
      svgHeight,
      myTagX,
      myTagY,
      myTagWidth,
      myTagHeight,
      averageTagX,
      averageY,
      averageWidth,
      averageHeight,
    };
  },
};
