
import UserService from '@/services/UserService';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonSpinner, IonText, IonButton } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '../../components/PageHeader.vue'


export default defineComponent({
  name: 'ConfirmPage',
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid, IonRow, IonCol, IonSpinner, IonText, IonButton
  }, 
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const isLoading = ref(false)
    const userId = ref('')
    const code = ref('')
    const isConfirmed = ref(false)

    onMounted(() => {
      isLoading.value = true
      
      if (route.params.userId && route.params.code) {
        userId.value = route.params.userId.toString()
        code.value = route.params.code.toString()
        UserService.confirmEmail(userId.value, code.value).then((value: boolean) => {
          isLoading.value = false
          if (value) {
            isConfirmed.value = true
          } else {
            isConfirmed.value = false
          }
        }).catch(() => {
          isLoading.value = false
          router.push('/')
        })
      } else {
        router.push('/')
      }
    })
    return {
      t,
      isLoading,
      isConfirmed,
      router
    }
  }
});
