
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonMenuToggle,
  IonItem,
  IonContent,
  IonMenu,
  IonModal,
  IonIcon,
  IonButton,
  IonButtons
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import UserService from "./services/UserService";
import { useStore } from "./store";
import { User } from "./types";
import { Storage } from '@capacitor/storage';
import { isTracking, useState } from "vue-gtag-next";
import CookieComplyModal from 'vue-cookie-comply-with-reject'
import { closeOutline, trophy } from "ionicons/icons";


export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonMenuToggle,
    IonItem,
    IonContent,
    IonMenu,
    CookieComplyModal,
    IonModal,
    IonIcon,
    IonButton,
    IonButtons
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const isReady = ref(false);
    const isLoggedIn = store.state.isLoggedIn;
    const appConfig = store.state.appConfig
    const { isEnabled } = useState();
    const isCookieModalOpen = ref(false)
    const isGainingNewPointsModalOpen = ref(false)
    const gainedUserPoints = ref(0)
    const setIsGainingNewPointsModalOpen = (state: boolean, gainedPoints: number) => {
      isGainingNewPointsModalOpen.value = state;
      gainedUserPoints.value = gainedPoints
    };

    UserService.getUserFromStorage().then(async (user: User) => {
      const cookiesPreferences = localStorage.getItem('cookie-comply')
      console.log(cookiesPreferences)
      if (cookiesPreferences) {
        if (cookiesPreferences === 'all') {
          if (typeof isEnabled?.value !== 'undefined') {
            isEnabled.value = true
          }
        } else if (JSON.parse(cookiesPreferences).includes('ga')) {
          if (typeof isEnabled?.value !== 'undefined') {
            isEnabled.value = true
          }
        }
      }
      
      if (user) {
        const userPoints = await UserService.getPoints()

        if (user?.points && userPoints > user.points) {
          setIsGainingNewPointsModalOpen(true, userPoints - user.points)
          user.points = userPoints
          await UserService.setUserInStorage(user)
        }
      }

      store.setUser(user).then(() => {
        Storage.get({key: 'taggingResults'}).then((taggingResults) => {
          if (taggingResults.value) {
            store.state.taggingResults.value = JSON.parse(taggingResults.value)
          }

          store.getAppConfig().then(() => {
            isReady.value = true
          }).catch(() => {
            //set default app config when configs are not set in backend
            if (store.state.appConfig.value) {
              store.state.appConfig.value.useFullUser = false
            }
            
            setTimeout(() => {
              isReady.value = true
            }, 500);
          })
        })
        
      });
    });

    const logout = async () => {
      await UserService.setUserInStorage(null);
      await store.setUser(null);
      await router.push("/login");
    };

    const preferences = [
      {
        title: 'Statistik',
        description: 'Nutzerstatistiken mit Google Analytics.',
        items: [{ label: 'Google Analytics', value: 'ga', isRequired: false }],
      }
    ]

    const onAcceptAllCookies = () => {
      activateGoogleAnalytics()
    }

    const onSavePreferences = (preferences: string[]) => {
      if (preferences.includes('ga')) {
        activateGoogleAnalytics()
      } else {
        deactivateGoogleAnalytics()
      }
    }

    onMounted(() => {
      setTimeout(() => {
        const btns = document.querySelectorAll('.cookie-comply__actions .cookie-comply__button')
        for(const btn of btns) {
          btn.addEventListener('click', () => {
            console.log('clicked')
            const cookieSaveBtn = document.querySelector('.cookie-comply__modal .cookie-comply__modal-footer .cookie-comply__button')
            if (cookieSaveBtn) {
              cookieSaveBtn.innerHTML = 'Speichern'
            }
          })
        }

        document.querySelector('.cookie-comply__edit-button')?.addEventListener('click', () => {
          console.log('clicked')
          const cookieSaveBtn = document.querySelector('.cookie-comply__modal .cookie-comply__modal-footer .cookie-comply__button')
          if (cookieSaveBtn) {
            cookieSaveBtn.innerHTML = 'Speichern'
          }
        })
      }, 600);
    })

    const activateGoogleAnalytics = () => {
      if (typeof isEnabled?.value  !== 'undefined') {
        isEnabled.value = true
      }
    }

  const deactivateGoogleAnalytics = () => {
    if (isEnabled?.value) {
      isEnabled.value = false
    }
  }

  const calculateVh = () => {
  var vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', vh + 'px')
  }
  // Initial calculation
  calculateVh()

  // Re-calculate on resize
  window.addEventListener('resize', calculateVh)
  // Re-calculate on device orientation change
  window.addEventListener('orientationchange', calculateVh)

    return {
      t,
      router,
      isReady,
      logout,
      isLoggedIn,
      appConfig,
      preferences,
      onAcceptAllCookies,
      onSavePreferences,
      isCookieModalOpen,
      isGainingNewPointsModalOpen,
      setIsGainingNewPointsModalOpen,
      gainedUserPoints,
      closeOutline,
      trophy
    };
  },
});
