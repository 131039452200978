import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9503e378"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_loader = _resolveComponent("image-loader")!
  const _component_text_loader = _resolveComponent("text-loader")!
  const _component_button_loader = _resolveComponent("button-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_image_loader, { height: '500px' }),
    _createVNode(_component_text_loader, { lines: [{height: '40px', width: '20%'}, {height: '20px', width: '95%'}, {height: '20px', width: '95%'}, {height: '20px', width: '90%'}, {height: '20px', width: '90%'}, {height: '20px', width: '30%'}] }),
    _createVNode(_component_button_loader, {
      height: '40px',
      width: '20%'
    })
  ]))
}