import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33db1031"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_loader = _resolveComponent("text-loader")!
  const _component_item_loader = _resolveComponent("item-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_text_loader, { lines: [{height: '30px', width: '20%'}] }),
    _createVNode(_component_item_loader, {
      height: '60px',
      width: '100%'
    }),
    _createVNode(_component_item_loader, {
      height: '55px',
      width: '100%'
    }),
    _createVNode(_component_item_loader, {
      height: '50px',
      width: '100%'
    }),
    _createVNode(_component_item_loader, {
      height: '40px',
      width: '100%'
    }),
    _createVNode(_component_item_loader, {
      height: '40px',
      width: '100%'
    }),
    _createVNode(_component_item_loader, {
      height: '40px',
      width: '100%'
    })
  ]))
}