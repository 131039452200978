
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  menuController,
  IonButton
} from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Keyboard, Navigation, Pagination } from "swiper";
import "swiper/scss/keyboard";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/css";
import BaseService from "@/services/BaseService";
import "@ionic/vue/css/ionic-swiper.css";

export default defineComponent({
  name: "BoardingPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
    IonButton
  },
  setup() {
    const router = useRouter();
    const slides = [
      {
        image: '/assets/img/boarding/onboarding_RZ_01.png'
      },
      {
       image: '/assets/img/boarding/onboarding_RZ_02.png'
      },
      {
        image: '/assets/img/boarding/onboarding_RZ_03.png'
      },
      {
       image: '/assets/img/boarding/onboarding_RZ_04.png'
      },
      {
       image: '/assets/img/boarding/onboarding_RZ_05.png'
      },
      {
       image: '/assets/img/boarding/onboarding_RZ_06.png'
      }
    ]

    const goToHomePage = async () => {
      await BaseService.setShowBoarding()
      menuController.enable(true)
      router.push('/home')
    }

    onMounted(() => {
      menuController.enable(false)
    })

    return {
      router,
      slides,
      modules: [Keyboard, Navigation, Pagination],
      goToHomePage
    };
  },
});
