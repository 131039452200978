
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonButton,
  onIonViewDidEnter
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import PageHeader from "../components/PageHeader.vue";
import SideMenu from "../components/SideMenu.vue";
import WorkService from "@/services/WorkService";
import ItemListLoader from "@/components/skeleton-loaders/ItemListLoader.vue";
import CardListLoader from "@/components/skeleton-loaders/CardListLoader.vue";
import WorkPageLoader from "@/components/skeleton-loaders/WorkPageLoader.vue";

export default defineComponent({
  name: "LocalInfoPage",
  components: {
    IonContent,
    IonPage,
    PageHeader,
    SideMenu,
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    IonCard,
    IonLabel,
    IonCardHeader,
    IonCardTitle,
    ItemListLoader,
    CardListLoader,
    WorkPageLoader,
    IonButton
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const step = ref(0);
    const isReady = ref(false);
    const currentlocation = route.params.location;
    const locations = store.state.locations;
    const institutions = ref();
    const institution = ref();
    const work = ref();
    const selectedObjectId = ref(0)
    const selectedObjectTitle = ref("")
    const taggingButtonLabel = " auf diesem Bild taggen"
    const initialTaggingButtonLabel = "Dieses Bild taggen"

    onIonViewDidEnter(async () => {
      if (route.params.location && route.params.institution) {
        if (route.params.id) {
          step.value = 4;
          work.value = await WorkService.getWork(Number(route.params.id));
        } else {
          step.value = 3;
          institution.value = await WorkService.getInstitution(
            route.params.institution as string,
            route.params.location as string
          );
        }
      }

      if (route.params.location && route.params.institution === "") {
        step.value = 2;
        institutions.value = await WorkService.getInstitutions(
          route.params.location as string
        );
      } else if (
        route.params.location === "" &&
        route.params.institution === ""
      ) {
        step.value = 1;
        await store.getAllLocations();
      }

      isReady.value = true;
    });

    const loadImage = (dia_nr: string, elClass: string) => {
      const img = new Image();
      img.onload = () => {
        const imgEl = document.querySelector<HTMLImageElement>("." + elClass);
        if (imgEl) {
          imgEl.src = img.src;
        }
      };
      img.src =
        "https://realonline.imareal.sbg.ac.at/imageservice/kupo/" + dia_nr;

      return (
        "https://realonline.imareal.sbg.ac.at/iipsrv/iipsrv.fcgi?FIF=/" +
        dia_nr +
        ".tif&WID=120&HEI=120&CVT=JPG"
      );
    };

    return {
      currentlocation,
      isReady,
      institutions,
      institution,
      work,
      locations,
      router,
      step,
      loadImage,
      selectedObjectId,
      selectedObjectTitle,
      initialTaggingButtonLabel,
      taggingButtonLabel
    };
  },
});
