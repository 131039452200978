import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192e2b44"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["xlink:href", "width", "height"]
const _hoisted_3 = ["x", "y", "width", "height"]
const _hoisted_4 = ["x", "y", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    version: "1.1",
    width: $setup.svgWidth,
    height: $setup.svgHeight,
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink"
  }, [
    _createElementVNode("image", {
      class: "tag-image",
      "xlink:href": $props.tagImage.src,
      x: "0",
      y: "0",
      preserveAspectRatio: "none",
      width: $setup.svgWidth,
      height: $setup.svgHeight
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      class: "my-tag",
      x: $setup.myTagX,
      y: $setup.myTagY,
      width: $setup.myTagWidth,
      height: $setup.myTagHeight
    }, null, 8, _hoisted_3),
    ($props.averageTag)
      ? (_openBlock(), _createElementBlock("rect", {
          key: 0,
          class: "average-tag",
          x: $setup.averageTagX,
          y: $setup.averageY,
          width: $setup.averageWidth,
          height: $setup.averageHeight
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}