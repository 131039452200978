
import { trophy } from "ionicons/icons";
import { onMounted, ref } from 'vue';
import {
  IonIcon
} from "@ionic/vue";
export default {
  name: "WinningEffect",
  components: {
    IonIcon
  },
  props: {
    rewardedPoints: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup() {
    const rewardEffectEl = ref()
    onMounted( () => {
      rewardEffectEl.value?.classList.add('animate')
      

      setTimeout(() => {
        rewardEffectEl.value.classList.remove('animate')
      }, 1500)
    })

    return {
      trophy,
      rewardEffectEl
    };
  },
};
