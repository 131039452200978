
import UserService from '@/services/UserService';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner } from '@ionic/vue';
import { defineComponent, reactive, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import PageHeader from '../../components/PageHeader.vue'
import { useVuelidate } from "@vuelidate/core"
import { required, email, minLength } from "@vuelidate/validators"
import BasicHelpers from '@/helpers/BasicHelpers';


export default defineComponent({
  name: 'RegisterPage',
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner
  }, 
  setup () {
    const router = useRouter()
    const { t } = useI18n()
    const isLoading = ref(false)

    const registerFrom = reactive({
      email: '',
      username: '',
      password: '',
      name: ''
    })

    const registerFromRules = {
      email: { required, email },
      username: { required },
      password: { required, minLength: minLength(6) },
      name: { required }
    }

    const validateregisterFrom = useVuelidate(registerFromRules, {
      email: toRef(registerFrom, 'email'),
      username: toRef(registerFrom, 'username'),
      password: toRef(registerFrom, 'password'),
      name: toRef(registerFrom, 'name')
    });

    // handle the submit of the form, only called
    // if the form is valid
    const onSubmit = async () => {
      validateregisterFrom.value.$touch();
      if (validateregisterFrom.value.$invalid) return

      isLoading.value = true
       UserService.register(registerFrom.username, registerFrom.password, registerFrom.name, registerFrom.email).then( async (isRegistred) => {
        isLoading.value = false
        if (isRegistred) {
          await BasicHelpers.presentToast(t('message.registerDone'), 5000, 'thing-tag')
          router.push('/login')
        }
       }).catch(() => {
        isLoading.value = false
      })
    }

    return {
      t,
      onSubmit,
      validateregisterFrom,
      isLoading
    }
  }
});
