import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { createStore, storeSymbol } from './store';
import { createI18n } from 'vue-i18n';
import { state } from './store/state';
import axios from 'axios';
import BasicHelpers from './helpers/BasicHelpers';
import UserService from './services/UserService';
import VueGtag from "vue-gtag-next";
import VueCookieComply from 'vue-cookie-comply-with-reject'
import 'vue-cookie-comply-with-reject/dist/style.css'
import { User } from './types';

/*
** loading the translations
*/
const messages = {
  'de': require('./messages/de.json'),
  'easy': require('./messages/easy.json'),
}

/*
** used for translations
*/
const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
})

// setting the api url
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_URL

/*
** Add a request interceptor
** here we add the Authorization header only for loged in user
*/
axios.interceptors.request.use(async function (config) {
  const user: User = await UserService.getUserFromStorage()
  if (user?.auth_key) {
    if (config.headers) {
      config.headers['Authorization'] = [
        'Bearer', user.auth_key
      ].join(' ') 
    }
  }
  else {
    if (config.headers) {
      delete config.headers['Authorization']
    }
  }
  return config
}, function (error) {
  return Promise.reject(error);
});

/*
** Add a response interceptor
** here we check the response code and notify the user with ionic toast
*/
axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (error.response && error.response.status === 422) {
    await BasicHelpers.presentToast(error.response.data.message, 5000, 'warning')
  }
  if (error.response && error.response.status === 401) {
    UserService.setUserInStorage(null)
    state.user.value = null
    router.push('/login')
    await BasicHelpers.presentToast('Please login', 5000, 'warning')
  }
  if (error.response && error.response.status === 404) {
    await BasicHelpers.presentToast('The requested page was not found', 5000, 'warning')
  }
  if (error.response && error.response.status === 409) {
    await BasicHelpers.presentToast(error.response.data.message, 5000, 'warning')
  }
  if (error.response && error.response.status === 500) {
    await BasicHelpers.presentToast('Internal server error.', 5000, 'warning')
  }
  if (error.response && error.response.status > 500) {
    // might occur: 502 - Bad Gateway, 503 - Service Unavailable
    await BasicHelpers.presentToast('Server-side error + ' + error.response.status + ': ' + error.response.data, 5000, 'warning')
  }
  return Promise.reject(error)
});

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: true,
    mode: 'md'
  })
  .provide(storeSymbol, createStore())
  .use(i18n)
  .use(VueGtag, {
    property: { id: "G-CK39D8KM9G" },
    isEnabled: false
  })
  .use(VueCookieComply)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});