
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import {
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  IonContent,
  IonButton,
  IonIcon,
  IonAlert
} from "@ionic/vue";
import SideMenu from "../components/SideMenu.vue";
import { ComponentOptions, defineComponent, onMounted, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { A11y, Keyboard, Navigation, Pagination, Zoom } from "swiper";
import "swiper/scss";
import "swiper/scss/keyboard";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/scss/zoom";
import "@ionic/vue/css/ionic-swiper.css";
import { closeOutline, chevronDown } from "ionicons/icons";

export default defineComponent({
  name: "InfotextPageModal",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
    IonContent,
    SideMenu,
    IonButton,
    IonIcon,
    IonAlert
  },
  props: {
    objectId: {
      type: Number,
      required: true,
    },
  },
  setup(props: ComponentOptions) {
    const isReady = ref(false);
    const store = useStore();
    const router = useRouter();
    const infotext = store.state.infoText;
    const showTaggingButton = ref (false)
    const isCloseAlertOpen = ref(false)
    const showScrollArrow = ref(false)

    onMounted(async () => {
      await store.getInfoTextRelatedToObject(props.objectId);
      isReady.value = true;

      window.addEventListener('resize', function() {
        setShowScrollArrow()
      }, true);

      setShowScrollArrow()
    });

    const onContentScroll = (event: any) => {
      if (event.detail.scrollTop > 20) {
        showScrollArrow.value = false;
      } else {
        showScrollArrow.value = true;
      }
    }

    const setShowScrollArrow = () => {
      setTimeout(() => {
        const modal = document.querySelector('.info-text-modal')
        const container = document.querySelector('.infotext-view')
        if (modal && container) {
          if (container.clientHeight > modal.clientHeight) {
            showScrollArrow.value = true
          } else {
            showScrollArrow.value = false
          }
        }
      }, 1000);
      
    }

    const dismiss = async () => {
      await modalController.dismiss();
    };

    const swiperReachEnd = () => {
      showTaggingButton.value = true
    }

    const fixHTML = (cHTML: string) => {
      return cHTML.replace('http://backend.thingtag.at','https://backend.thingtag.at')
    }

    const goToLongText = async () => {
      await dismiss()
      router.push('/explore/' + infotext.value?.id)
    }

    const setCloseAlertOpen = (state: boolean) => {
      isCloseAlertOpen.value = state
    }

    const alertButtons = [
        {
          text: 'BLEIBEN',
          role: 'cancel',
          cssClass: 'black-color',
          handler: () => {
            setCloseAlertOpen(false)
          },
        },
        {
          text: 'SCHLIESSEN',
          role: 'confirm',
          cssClass: 'black-color',
          handler: async () => {
            setCloseAlertOpen(false)
            await dismiss()
            router.push('/')
          },
        },
      ];

    const scrollDown = () => {
      const modal = document.querySelector('.info-text-modal')
      const container = document.querySelector('.infotext-view')
      if (modal && container) {
        console.log('down',container.scrollHeight)
        container.scrollIntoView({ behavior: "smooth", block: "end"})
      }
    }

    return {
      isReady,
      infotext,
      modules: [A11y, Keyboard, Navigation, Pagination, Zoom],
      router,
      dismiss,
      showTaggingButton,
      swiperReachEnd,
      fixHTML,
      goToLongText,
      closeOutline,
      setCloseAlertOpen,
      isCloseAlertOpen,
      alertButtons,
      showScrollArrow,
      chevronDown,
      setShowScrollArrow,
      onContentScroll,
      scrollDown
    };
  },
});
