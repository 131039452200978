import UserSerice from '@/services/UserService';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import TopListPage from '../views/TopListPage.vue'
import TaggingPage from '../views/TaggingPage.vue'
import ExplorePage from '../views/ExplorePage.vue'
import LocalInfoPage from '../views/LocalInfoPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import LoginPage from '../views/user/LoginPage.vue'
import RegisterPage from '../views/user/RegisterPage.vue'
import ConfirmPage from '../views/user/ConfirmPage.vue'
import ResetPasswordPage from '../views/user/ResetPasswordPage.vue'
import BaseService from '@/services/BaseService';
import BoardingPage from '../views/BoardingPage.vue'
import AboutPage from '../views/AboutPage.vue'
import ImpressumPage from '../views/ImpressumPage.vue'
import DisclaimerPage from '../views/DisclaimerPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/boarding',
    name: 'Boarding',
    meta: { requiresAuth: false, introGuard: false },
    component: BoardingPage
  },
  {
    path: '/tagging/:objectId?/:workId?',
    name: 'Tagging for objects of this kind',
    meta: { requiresAuth: true, introGuard: true },
    component: TaggingPage
  },
  {
    path: '/explore/:id?',
    name: 'Explore',
    meta: { requiresAuth: false, introGuard: true },
    component: ExplorePage
  },
  {
    path: '/localinfo/:location?/:institution?/:id?',
    name: 'Local Info',
    meta: { requiresAuth: false, introGuard: true },
    component: LocalInfoPage
  },
  {
    path: '/top-list',
    name: 'Top list',
    meta: { requiresAuth: false, introGuard: true },
    component: TopListPage
  },
  {
    path: '/home',
    name: 'Home',
    meta: { requiresAuth: false, introGuard: true },
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: false, introGuard: false },
    component: AboutPage
  },
  {
    path: '/impressum',
    name: 'Impressum',
    meta: { requiresAuth: false, introGuard: false },
    component: ImpressumPage
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    meta: { requiresAuth: false, introGuard: false },
    component: DisclaimerPage
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false, introGuard: true },
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    meta: { requiresAuth: false, fullUserGuard: true, introGuard: true },
    component: RegisterPage
  },
  {
    path: '/confirm/:userId/:code',
    name: 'Confirm',
    meta: { requiresAuth: false, introGuard: true },
    component: ConfirmPage
  },
  {
    path: '/reset-password/:userId/:code',
    name: 'Reset password',
    meta: { requiresAuth: false, introGuard: true },
    component: ResetPasswordPage
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: false, introGuard: true },
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// routes guard   
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.fullUserGuard)) {
    BaseService.getAppConfig().then((data) => {
      if(data?.useFullUser) {
        next()
      } else {
        next('/home')
      }
    })
  }
  else if (to.matched.some((record) => record.meta.requiresAuth)) {
    UserSerice.isLoggedIn().then((value: boolean) => {
      if (!value) {
        next({path: '/login', query: {redirect: to.fullPath}})
      } else {
        next()
      }
    })
  }
  else if (to.matched.some((record) => record.meta.introGuard)) {
    BaseService.getShowBoarding().then((value: boolean) => {
      if (!value) {
        next({path: '/boarding'})
      } else {
        next()
      }
    })
  }
  else {
    next()
  }
})


export default router
