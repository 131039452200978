
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonToolbar,
  useIonRouter,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";
import UserPoints from './UserPoints.vue';
import { useStore } from '@/store';
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
    },
    canGoBack: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonToolbar,
    IonIcon,
    IonButton,
    UserPoints
},
  setup() {
    const ionRouter = useIonRouter();
    const router = useRouter();
    const store = useStore();
    const user = store.state.user

    const goBack = () => {
      if (ionRouter.canGoBack()) {
        return router.go(-1);
      } else {
        return router.push("/");
      }
    };

    const goHome = () => {
      return router.push("/");
    };

    return {
      goBack,
      goHome,
      arrowBack,
      user
    };
  },
};
