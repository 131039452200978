import { WorkObject } from "@/types";
import axios from "axios";

export default {
	async getWorkObject(id: number) {
		const response = await axios.get(`/work-object/work-object?id=${id}`)
		const workObject: WorkObject = response.data
		return workObject
	},

	async getRandomObject(excpetObjectId: number | null) {
		const response = await axios.get(`/work-object/random-work-object`, {params: {excpetObjectId: excpetObjectId}})
		const workObject: WorkObject = response.data
		return workObject
	},

	/*async getCategorizedList(params = null) {
		const response = await axios.get(`/work-object/categorized-objects-list`, { params: params })
		const workObjectCategories: WorkObjectCategory[] = response.data
		return workObjectCategories
	}*/
}