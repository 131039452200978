import {AppConfig, InfoText, InfoTextCategory, Location, TaggingResults, TaggingWork, User, WorkObject} from "@/types";
import { Ref, ref } from "vue";

type StateType = {
  appConfig: Ref<AppConfig>;
  user: Ref<User>;
  isLoggedIn: Ref<boolean>;
  locations: Ref<Location[]>;
  infotextCategories: Ref<InfoTextCategory[]>;
  // workObjectCategories: Ref<WorkObjectCategory[]>
  infoTexts: Ref<InfoText[]>;
  infoText: Ref<InfoText>;
  workObject: Ref<WorkObject>;
  taggingWorks: Ref<TaggingWork[]>;
  taggingResults: Ref<TaggingResults[]>;
  showInfoText: Ref<boolean>;
}

export const state: StateType = {
  appConfig: ref(null),
  user: ref(null),
  isLoggedIn: ref(false),
  locations: ref([]),
  infotextCategories: ref([]),
  // workObjectCategories: ref([]),
  infoTexts: ref([]),
  infoText: ref(null),
  workObject: ref(null),
  taggingWorks: ref([]),
  taggingResults: ref([]),
  showInfoText: ref(true)
}

export type State = StateType