import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_loader = _resolveComponent("image-loader")!
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_image_loader, { height: "100vh" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_cropper, {
      class: "cropper",
      src: $props.src,
      backgroundClass: "cropperbg",
      canvas: false,
      defaultPosition: $setup.defaultTagPosition,
      defaultSize: $setup.defaultTagSize,
      ref: "cropper",
      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.onChange($event))),
      onReady: _cache[1] || (_cache[1] = ($event: any) => ($setup.onReady($event)))
    }, null, 8, ["src", "defaultPosition", "defaultSize"])
  ], 64))
}