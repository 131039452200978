import { AppConfig } from "@/types";
import axios from "axios";
import { Storage } from '@capacitor/storage';

export default {
	async getAppConfig () {
		const response = await axios.get(`/app/config?XDEBUG_SESSION_START=PHPSTORM`)
		const appConfig: AppConfig = response.data
		return appConfig
	},

	async setShowBoarding () {
		await Storage.set({
			key: 'showBoarding',
			value: JSON.stringify(true),
		});
	},

	async getShowBoarding() {
		const value = await Storage.get({
			key: 'showBoarding',
		});
		return value.value ? true : false
	},

	async getFromStorage(key: string) {
		const { value } = await Storage.get({ key: key })
		console.log(value)
		if (value)
			return JSON.parse(value)
		else
			return null
	},
}