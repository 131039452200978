
import CardLoader from "./CardLoader.vue";
import { IonRow, IonCol } from "@ionic/vue";
import ItemLoader from "./ItemLoader.vue";
export default {
  components: { CardLoader, IonRow, IonCol, ItemLoader },
  name: "CardListLoader",
  props: {
    cards: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
