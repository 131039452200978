
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { useI18n } from "vue-i18n";
import { ref, watchEffect } from "vue";
import ImageLoader from "./skeleton-loaders/ImageLoader.vue";

export default {
  name: "ImageTagging",
  props: {
    src: {
      type: String,
      required: true,
    },
    setTagToDefault: {
      type: Boolean,
    },
  },
  emits: ["onTagging", "onTaggingReset"],
  components: {
    Cropper,
    ImageLoader,
  },
  setup(props: any, context: any) {
    const { t } = useI18n();
    const cropper = ref();
    const isReady = ref(false);

    const onChange = (event: any) => {
      const coordinates = event.coordinates;
      const image = event.image;
      context.emit("onTagging", coordinates, image);
    };

    watchEffect(() => {
      if (props.setTagToDefault) {
        if (cropper.value) {
          cropper.value.reset();
          context.emit("onTaggingReset", true);
        }
      }
    });

    const defaultTagPosition = () => {
      return {
        left: 100,
        top: 100,
      };
    };

    const defaultTagSize = () => {
      return {
        width: 200,
        height: 200,
      };
    };

    const onReady = () => {
      isReady.value = true;
    };

    return {
      t,
      cropper,
      onChange,
      defaultTagPosition,
      defaultTagSize,
      onReady,
      isReady,
    };
  },
};
