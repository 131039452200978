
export default {
  name: "TextLoader",
  props: {
    lines: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {};
  },
};
