
import {
  useIonRouter,
  IonIcon,
  IonRippleEffect,
  IonAlert
} from "@ionic/vue";
import { documentText, arrowBackOutline, arrowForwardOutline, chevronBackOutline, chevronForwardOutline, scanOutline, checkmarkOutline, bulbOutline, refreshOutline, informationOutline, cubeOutline, closeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import {onMounted, ref} from "vue";
export default {
  name: "SideMenu",
  props: {
    showGoBack: {
      type: Boolean,
      default: false,
    },
    showTagObject: {
      type: Number,
      default: 0,
    },
    showDismiss: {
      type: Boolean,
      default: false,
    },
    showHints: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    showSaveTag: {
      type: Boolean,
      default: false,
    },
    showWorkInfo: {
      type: Boolean,
      default: false,
    },
    showNextWork: {
      type: Boolean,
      default: false,
    },
    showSkipObject: {
      type: Boolean,
      default: false,
    },
    finishTagging: {
      type: Boolean,
      default: false
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    },
    disableSave: {
      type: Boolean,
      default: true
    }
  },
  emits: ["onSaveTag", "onReset", "onShowHint", "onShowWorkInfo", "onNextWork", "onSkipObject", "onTagObject", "onMoreInfo"],
  components: {
    IonIcon,
    IonRippleEffect,
    IonAlert
  },
  setup(props: any, context: any) {
    const ionRouter = useIonRouter()
    const router = useRouter()
    const isOpen = ref(false)
    const isAlertOpen = ref(false)
    const setAlertOpen = (state: boolean) => isAlertOpen.value = state;
    const alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'danger',
        handler: () => {
          console.log('Confirm Cancel')
        },
      },
      {
        text: 'Ok',
        handler: () => {
          router.push('/home')
        },
      },
    ];

    const goBack = () => {
      if (ionRouter.canGoBack()) {
        return router.go(-1);
      } else {
        return router.push("/");
      }
    };

    const toggleSideMenu = () => {
      isOpen.value = !isOpen.value
    };

    const saveTag = () => {
      context.emit("onSaveTag");
    };

    const reset = () => {
      context.emit("onReset");
    };

    const displayHints = () => {
      context.emit("onShowHint");
    };

    const displayWorkInfo = () => {
      context.emit("onShowWorkInfo");
    };

    const nextWork = () => {
      context.emit("onNextWork");
    };

    const skipObject = () => {
      context.emit("onSkipObject");
    };

    const tagObject = () => {
      context.emit("onTagObject");
    }

    const goToLongText = () => {
      context.emit("onMoreInfo")
    }

    const shake = () => {
      const sidemenu = document.querySelector<HTMLDivElement>('#sidemenu')
      sidemenu?.classList.add('shake-el')
      setTimeout(() => {
        sidemenu?.classList.remove('shake-el')
      }, 1700);
    }

    const inactivityTime = () => {
      let time: any
      
      const resetTime = () => {
        clearTimeout(time);
        time = setTimeout(() => {
          shake();
        }, 1000 * 10); // 10 seconds
      }
      
      window.onload = resetTime;
      window.onclick = resetTime;
      window.onkeypress = resetTime;
      window.ontouchstart = resetTime;
      window.onmousemove = resetTime;
      window.onmousedown = resetTime;
      window.addEventListener('scroll', resetTime, true);
    };

    onMounted(() => {
      setTimeout(() => {
        shake()
      }, 1000);

      // deactivated for now
      //inactivityTime()
    })

    return {
      isOpen,
      router,
      goBack,
      toggleSideMenu,
      saveTag,
      reset,
      displayHints,
      displayWorkInfo,
      nextWork,
      skipObject,
      documentText, arrowBackOutline, arrowForwardOutline, chevronBackOutline, chevronForwardOutline, scanOutline, checkmarkOutline, bulbOutline, refreshOutline, informationOutline, cubeOutline, closeOutline,
      isAlertOpen,
      setAlertOpen,
      alertButtons,
      tagObject,
      goToLongText
    };
  },
};
