
import { useStore } from "@/store";
import {
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, nextTick, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LongText",
  components: {
    IonCol,
    IonButton
  },
  props: {
    infotext: {
      type: Object,
      required: false
    },
    workObjectId: {
      type: Number,
      requied: false
    }
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    onMounted(() => {
      const $lightbox = document.getElementById('lightbox');
      // Hide Lightbox
      $lightbox?.addEventListener('click', () => {
          $lightbox.classList.remove('show');
      });
      
      nextTick(() => {
        const $images = document.querySelectorAll('p.infotext-view img');
        $images.forEach(function(img) {
          img.addEventListener("click", () => {
            if ($lightbox) {
              $lightbox.innerHTML = '<div class="close-lightbox"></div>' + img.outerHTML;
              $lightbox.classList.add('show');
            }
          });
        });
      });
    })

    const goTagging = (workObjectId: number | undefined) => {
      store.state.showInfoText.value = false
      router.push('/tagging/' + workObjectId)
    }

    return {
      goTagging,
    };
  },
});
