import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c22c49"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "ion-padding-top loader"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        canGoBack: false,
        title: _ctx.$t('message.emailConfirmation')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-xs": "12",
                    "size-sm": "12",
                    "size-md": "6",
                    "size-lg": "4",
                    "size-xl": "3"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.confirmingEmail')), 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.isLoading)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_component_ion_spinner, { color: "thing-tag" })
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isConfirmed)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_ion_text, { color: "thing-tag" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.emailConfirmed')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/login')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.login')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}