import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51d1d102"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }
const _hoisted_2 = { class: "error" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = { class: "error" }
const _hoisted_5 = { class: "ion-padding-top" }
const _hoisted_6 = {
  key: 0,
  class: "ion-padding-top loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        canGoBack: true,
        title: _ctx.$t('message.register')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-xs": "12",
                    "size-sm": "12",
                    "size-md": "6",
                    "size-lg": "4",
                    "size-xl": "3"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("form", {
                        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                        novalidate: "",
                        class: "ion-padding"
                      }, [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, {
                              color: "dark",
                              position: "floating"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.email')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              type: "email",
                              name: "email",
                              modelValue: _ctx.validateregisterFrom.email.$model,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validateregisterFrom.email.$model) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.validateregisterFrom?.email?.$errors[0]?.$message), 1),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, {
                              color: "dark",
                              position: "floating"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.username')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              type: "text",
                              name: "username",
                              modelValue: _ctx.validateregisterFrom.username.$model,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validateregisterFrom.username.$model) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.validateregisterFrom?.username?.$errors[0]?.$message), 1),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, {
                              color: "dark",
                              position: "floating"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.name')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              type: "text",
                              name: "name",
                              modelValue: _ctx.validateregisterFrom.name.$model,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.validateregisterFrom.name.$model) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.validateregisterFrom?.name?.$errors[0]?.$message), 1),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, {
                              color: "dark",
                              position: "floating"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('message.password')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              type: "password",
                              name: "password",
                              modelValue: _ctx.validateregisterFrom.password.$model,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validateregisterFrom.password.$model) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.validateregisterFrom?.password?.$errors[0]?.$message), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ion_button, {
                            class: "ion-margin-top",
                            color: "thing-tag",
                            expand: "block",
                            type: "submit"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('message.register')), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.isLoading)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_ion_spinner, { color: "thing-tag" })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ], 32)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}