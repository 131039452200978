
import UserService from '@/services/UserService';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner, IonText } from '@ionic/vue';
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '../../components/PageHeader.vue'
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { minLength } from '@vuelidate/validators';


export default defineComponent({
  name: 'ResetPassword',
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner, IonText
  }, 
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const isLoading = ref(false)
    const userId = ref('')
    const code = ref('')
    const isReady = ref(false)
    const isChanged = ref(false)

    onMounted(() => {
      isLoading.value = true
      
      if (route.params.userId && route.params.code) {
        userId.value = route.params.userId.toString()
        code.value = route.params.code.toString()

        UserService.checkResetPasswordLink(userId.value, code.value).then((value: boolean) => {
          isLoading.value = false
          if (value) {
            isReady.value = true
          } else {
            isReady.value = false
          }
        }).catch(() => {
          isLoading.value = false
          router.push('/')
        })
      } else {
        router.push('/')
      }
    })

    const resetPasswordForm = reactive({
      newPassword: ''
    })

    const resetPasswordFormRules = {
      newPassword: { required, minLength: minLength(6) }
    }

    const validateResetPasswordForm = useVuelidate(resetPasswordFormRules, {
      newPassword: toRef(resetPasswordForm, 'newPassword')
    });

    // handle the submit of the form, only called
    // if the form is valid
    const resetPassword = async () => {
      validateResetPasswordForm.value.$touch();
      if (validateResetPasswordForm.value.$invalid) return

      isLoading.value = true
      UserService.resetPassword(userId.value, code.value, resetPasswordForm.newPassword).then(async (value) => {
        isLoading.value = false
        if (value) {
          isChanged.value = true
        } else {
          isChanged.value = false
        }
      }).catch(() => {
        isLoading.value = false
      })
      
    };

    return {
      t,
      resetPassword,
      validateResetPasswordForm,
      isLoading,
      isChanged,
      isReady,
      router
    }
  }
});
