import { Tag, User } from "@/types";
import { Storage } from '@capacitor/storage';
import axios from "axios";

export default {
	async login(login: string, password: string | null) {
		const response = await axios.post(`/user/login`, { login: login, password: password })
		const user: User = response.data
		return user
	},

	async register(username: string, password: string, name: string, email: string) {
		const response = await axios.post(`/user/register`, { username: username, password: password, name: name, email: email })
		const isregistred: boolean = response.data
		return isregistred
	},

	async lightRegister(name: string) {
		const response = await axios.post(`/user/light-register`, { name: name })
		const user: User = response.data
		return user
	},

	async confirmEmail(userId: string, authToken: string) {
		const response = await axios.post(`/user/confirm`, { userId: userId, authToken: authToken })
		const isConfirmed: boolean = response.data
		return isConfirmed
	},

	async sendResetpasswordLink(email: string) {
		const response = await axios.post(`/user/send-recovery-email`, { email: email })
		const isSent: boolean = response.data
		return isSent
	},

	async checkResetPasswordLink(userId: string, code: string) {
		const response = await axios.post(`/user/check-recovery-link`, { userId: userId, code: code })
		const isValid: boolean = response.data
		return isValid
	},

	async resetPassword(userId: string, code: string, newPassword: string) {
		const response = await axios.post(`/user/reset-password`, { userId: userId, code: code, newPassword: newPassword })
		const ispasswordChanged: boolean = response.data
		return ispasswordChanged
	},

	async resendConfirmationLink(email: string) {
		const response = await axios.post(`/user/resend-confirmation-link`, { email: email })
		const isSent: boolean = response.data
		return isSent
	},

	async getUserTags(userId: number) {
		const response = await axios.post(`/user/get-user-tags`, { userId: userId })
		const tags: Tag[] = response.data
		return tags
	},

	async setUserInStorage(user: User) {
		Storage.set({
			key: 'user',
			value: JSON.stringify(user),
		});
	},

	async saveUserTag(tag: Tag) {
		const response = await axios.post(`/user/save-user-tag`, { tag: tag })
		const result: any = response.data
		return result
	},

	async removeUserTag(tag: Tag) {
		const response = await axios.post(`/user/delete-user-tag`, { tag: tag })
		const isDeleted: boolean = response.data
		return isDeleted
	},

	async removeUser(userId: Tag) {
		const response = await axios.post(`/user/delete`, { userId: userId })
		const isDeleted: boolean = response.data
		return isDeleted
	},

	async getUserFromStorage() {
		const { value } = await Storage.get({ key: 'user' })
		if (value)
			return JSON.parse(value)
		else
			return null
	},


	/*
	** to be used when no possibilty to use store i.e: in router->index.ts and main.ts
	*/
	async isLoggedIn() {
		const { value } = await Storage.get({ key: 'user' })
		if (value) {
			const user: User = JSON.parse(value)
			if (user) {
				if (user.light_registration) {
					return !!user.username
				} else {
					return !!user.auth_key
				}
			} else {
				return false
			}
		}
		else
			return false
	},

	async topList(userId: number | null) {
		const response = await axios.get(`/user/top-list?userId=${userId}`)
		const result: any = response.data
		return result
	},

	async getPoints() {
		const response = await axios.get(`/user/get-points`)
		const result: any = response.data
		return result
	}
}