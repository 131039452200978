
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  IonModal,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import PageHeader from "../components/PageHeader.vue";
import ImageTagging from "../components/ImageTagging.vue";
import { close, trophy } from "ionicons/icons";
import { Coordinates } from "vue-advanced-cropper";
import ResultImage from "../components/ResultImage.vue";
import InfotextPageModal from "./InfotextPageModal.vue";
import WorkObjectService from "@/services/WorkObjectService";
import TaggingPageLoader from "@/components/skeleton-loaders/TaggingPageLoader.vue";
import { TaggingResults, Work, WorkObject } from "@/types";
import SideMenu from "../components/SideMenu.vue";
import WinningEffect from "@/components/WinningEffect.vue";

export default defineComponent({
  name: "TaggingPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    PageHeader,
    ImageTagging,
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButtons,
    IonModal,
    ResultImage,
    TaggingPageLoader,
    SideMenu,
    WinningEffect
  },
  setup() {
    const isReady = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const workObject = store.state.workObject;
    const taggingWorks = store.state.taggingWorks;
    const tagCoordinates = ref<Coordinates>();
    const tagImage = ref<any>();
    const currentSubTaskIndex = ref(0);
    const workIndex = ref(0);
    const setTagToDefault = ref(false);
    const user = store.state.user;
    const results = ref<TaggingResults[]>([]);
    const imageInfoClass = "imageinfo-modal";
    const hintClass = "hint-modal";
    const showSideMenu = ref(false);
    const showInfoText = store.state.showInfoText;
    const disableSave = ref(false);

    const isHintModalPresented = ref(false);
    const tippsModelDidDismiss = () => setHintModelPresented(false);
    const setHintModelPresented = (state: boolean) =>
      (isHintModalPresented.value = state);

    const isImageInfoModalPresented = ref(false);
    const imageInfoModelDidDismiss = () => setImageInfoModelPresented(false);
    const setImageInfoModelPresented = (state: boolean) =>
      (isImageInfoModalPresented.value = state);

    const isResultModalPresented = ref(false);
    const resultModelDidDismiss = () =>
      setResultModelPresented(
        false,
        workIndex.value,
        currentSubTaskIndex.value,
        false
      );
    const setResultModelPresented = (
      state: boolean,
      index: number,
      subTaskIndex: number,
      goNext: boolean
    ) => {
      isResultModalPresented.value = state;
      setTimeout(() => {
        if (goNext) {
          const works = taggingWorks.value;
          const subTasks = works[index].related_work_object_usages;
          if (subTasks.length !== subTaskIndex + 1) {
            currentSubTaskIndex.value = subTaskIndex + 1;
          } else {
            currentSubTaskIndex.value = 0;
            if (index + 1 !== works?.length) {
              workIndex.value = index + 1;
            } else {
              if (index + 1 === works?.length) {
                workIndex.value = works?.length + 1;
              }
            }
          }
        }
      }, 500);

      document.querySelector("image-tagging")?.scrollIntoView();
    };
    const taggedUsages = ref<number[]>();

    const openInfotextModal = async () => {
      showSideMenu.value = false;
      const modal = await modalController.create({
        component: InfotextPageModal,
        cssClass: "info-text-modal",
        backdropDismiss: false,
        componentProps: {
          objectId: workObject.value?.id,
        },
      });

      modal.onDidDismiss().then(() => {
        showSideMenu.value = true;
      });
      return modal.present();
    };

    onMounted(async () => {
      isReady.value = false;
      taggedUsages.value = [];
      for (let result of store.state.taggingResults.value) {
        const workObjectUsageId = result.work_object_usage_id;
        if (!taggedUsages.value.includes(workObjectUsageId)) {
          taggedUsages.value.push(result.work_object_usage_id);
        }
      }

      if (route.params.objectId && route.params.workId) {
        const workObject = await WorkObjectService.getWorkObject(
          parseInt(route.params.objectId.toString())
        );
        store.state.workObject.value = workObject;
        if (workObject) {
          await store.getTaggingWork(
            workObject.id,
            parseInt(route.params.workId.toString())
          );
        }
      } else if (route.params.objectId && !route.params.workId) {
        const workObject = await WorkObjectService.getWorkObject(
          parseInt(route.params.objectId.toString())
        );
        store.state.workObject.value = workObject;
        if (store.state.workObject.value?.id) {
          const tagged = taggedUsages.value ? taggedUsages.value : [];
          await store.getRandomTaggingWorks(
            store.state.workObject.value?.id,
            [],
            tagged
          );
        }
      } else {
        await store.getRandomObject(null);
        if (store.state.workObject.value?.id) {
          const tagged = taggedUsages.value ? taggedUsages.value : [];
          await store.getRandomTaggingWorks(
            store.state.workObject.value?.id,
            [],
            tagged
          );
        }
      }

      await setCurrentWorkObject()

      isReady.value = true;
      if (showInfoText.value) {
        await openInfotextModal();
      } else {
        showSideMenu.value = true;
        store.state.showInfoText.value = true;
      }
    });

    const currentWorkObject = ref<WorkObject>()
    const setCurrentWorkObject = async() => {
      const id = taggingWorks.value[workIndex.value]?.workObjectId
      if (id && currentWorkObject.value?.id !== id) {
        currentWorkObject.value = await WorkObjectService.getWorkObject(id)
      }
    }

    onUnmounted(() => {
      store.state.workObject.value = null;
      currentWorkObject.value = null
    });

    const showInfotext = async () => {
      await openInfotextModal();
    };

    const setCoordinates = (coordinates: any, image: any) => {
      disableSave.value = userTaggedAllImage(coordinates, image);

      tagCoordinates.value = coordinates;
      tagImage.value = image;
    };

    const userTaggedAllImage = (coordinates: any, image: any) => {
      return (
        coordinates.width > image.width / 1.5 ||
        coordinates.height > image.height / 1.5
      );
    };

    const addToLocalResult = (result: TaggingResults) => {
      if (!taggedUsages.value) {
        taggedUsages.value = [];
      }

      if (!taggedUsages.value.includes(result.work_object_usage_id)) {
        taggedUsages.value.push(result.work_object_usage_id);
      }
    };

    const saveTag = async (
      index: number,
      subTaskIndex: number,
      relatedWorkObjectUsage: number
    ) => {
      if (user.value && tagCoordinates.value) {
        const works = taggingWorks.value;
        const object = workObject.value;
        if (object && works) {
          const data = await store.saveUserTag({
            x: tagCoordinates.value.left,
            y: tagCoordinates.value.top,
            width: tagCoordinates.value.width,
            height: tagCoordinates.value.height,
            work_id: works[index]?.id,
            object_id: object.id,
            user_id: user.value?.id,
            work_object_usage_id: relatedWorkObjectUsage,
            rewarded: false,
          });
          user.value.points = data.points
          await store.updateUser(user.value);

          const workId = works[index]?.id;

          const result: TaggingResults = {
            work_id: workId ? workId : 0,
            object_id: object.id,
            averageTag: data.averageTag,
            rewarded: data.rewarded,
            work_object_usage_id: relatedWorkObjectUsage,
            subTaskIndex: subTaskIndex,
          };
          results.value?.push(result);

          await store.addToResults(result);
          addToLocalResult(result);
        }
      }

      setResultModelPresented(
        true,
        workIndex.value,
        currentSubTaskIndex.value,
        false
      );
    };

    const reset = () => {
      setTagToDefault.value = true;
    };

    const nextWork = async (index: number, subTaskIndex: number) => {
      // const object = workObject.value;
      const works = taggingWorks.value;
      const subTasks = works[index].related_work_object_usages;

      if (subTasks.length !== subTaskIndex + 1) {
        currentSubTaskIndex.value = subTaskIndex + 1;
      } else {
        currentSubTaskIndex.value = 0;
        if (index + 1 !== works?.length) {
          workIndex.value = index + 1;
        } else {
          if (index + 1 === works?.length) {
            workIndex.value = works?.length + 1;
          }
        }
      }
      await setCurrentWorkObject()

      document.querySelector("image-tagging")?.scrollIntoView();
    };

    const chooseAnotherObject = async (closeResultModal: boolean) => {
      isReady.value = false;
      workIndex.value = 0;
      if (closeResultModal) {
        await modalController.dismiss();
      }
      if (store.state.workObject.value?.id) {
        await store.getRandomObject(store.state.workObject.value?.id);
        if (store.state.workObject.value?.id) {
          const tagged = taggedUsages.value ? taggedUsages.value : [];
          await store.getRandomTaggingWorks(
            store.state.workObject.value?.id,
            [],
            tagged
          );
          await setCurrentWorkObject()
        }
        isReady.value = true;
        await openInfotextModal();
      }
    };

    const getMoreRandomtaggingWorks = async () => {
      isReady.value = false;
      workIndex.value = 0;
      let taggedWorkIds = [];
      for (let item of results.value) {
        const workId = item.work_id;
        if (typeof workId !== "undefined") {
          taggedWorkIds.push(item?.work_id);
        }
      }

      if (store.state.workObject.value?.id) {
        const tagged = taggedUsages.value ? taggedUsages.value : [];
        await store.getRandomTaggingWorks(
          store.state.workObject.value?.id,
          taggedWorkIds,
          tagged
        );
        await setCurrentWorkObject()
        isReady.value = true;
      } else {
        isReady.value = true;
      }
    };

    const getAverageTag = (
      objectId: number | undefined,
      workId: number,
      subTaskIndex: number,
      relatedWorkObjectUsage: number
    ) => {
      return results.value?.find(
        (item) =>
          item.work_id === workId &&
          item.object_id === objectId &&
          item.subTaskIndex === subTaskIndex &&
          item.work_object_usage_id === relatedWorkObjectUsage
      )?.averageTag;
    };

    const getIsRewarded = (
      objectId: number | undefined,
      workId: number,
      subTaskIndex: number,
      relatedWorkObjectUsage: number
    ) => {
      return results.value?.find(
        (item) =>
          item.work_id === workId &&
          item.object_id === objectId &&
          item.subTaskIndex === subTaskIndex &&
          item.work_object_usage_id === relatedWorkObjectUsage
      )?.rewarded;
    };

    const getWorkImageUrl = (work: Work) => {
      if (work != null) {
        //return 'https://realonline.imareal.sbg.ac.at/imageservice/kupo/' + work.dia_nr
        return (
          "https://realonline.imareal.sbg.ac.at/iipsrv/iipsrv.fcgi?FIF=/" +
          work.dia_nr +
          ".tif&WID=1600&HEI=1600&CVT=JPG"
        );
      }
    };

    const getBgImageUrl = (work: Work) => {
      if (work != null) {
        return (
          "https://realonline.imareal.sbg.ac.at/iipsrv/iipsrv.fcgi?FIF=/" +
          work.dia_nr +
          ".tif&WID=75&HEI=75&CVT=JPG"
        );
      }
    };

    const showPackageCompleted = () => {
      return workIndex.value === taggingWorks.value.length + 1;
    };

    const noMoreWorks = () => {
      return taggingWorks.value.length === 0
    }

    return {
      isReady,
      router,
      workObject,
      taggingWorks,
      showInfotext,
      setCoordinates,
      currentSubTaskIndex,
      workIndex,
      setTagToDefault,
      saveTag,
      reset,
      isHintModalPresented,
      tippsModelDidDismiss,
      setHintModelPresented,
      close,
      trophy,
      isImageInfoModalPresented,
      imageInfoModelDidDismiss,
      setImageInfoModelPresented,
      nextWork,
      chooseAnotherObject,
      isResultModalPresented,
      resultModelDidDismiss,
      setResultModelPresented,
      results,
      getAverageTag,
      getIsRewarded,
      tagCoordinates,
      tagImage,
      getMoreRandomtaggingWorks,
      getWorkImageUrl,
      getBgImageUrl,
      showPackageCompleted,
      imageInfoClass,
      hintClass,
      showSideMenu,
      disableSave,
      noMoreWorks,
      currentWorkObject
    };
  },
});
