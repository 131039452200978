
import { useStore } from '@/store';
import { computed, watchEffect } from 'vue';
import { trophy } from "ionicons/icons";
import {
  IonIcon
} from "@ionic/vue";
import { useRouter } from 'vue-router';
export default {
  name: "UserPoints",
  components: {
    IonIcon
  },
  props: {
  },
  setup() {
    const store = useStore()
    const user = store.state.user
    const router = useRouter();

    const getPoints = computed(() => {
      return user.value?.points
    })

    watchEffect(() => {
      if (user.value?.points) {
        const pointsEl = document.querySelector('.points')
        pointsEl?.classList.add('animate')

        setTimeout(() => {
          pointsEl?.classList.remove('animate')
        }, 1000)
      }
    })

    return {
      getPoints,
      trophy,
      router
    };
  },
};
