import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_user_points = _resolveComponent("user-points")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    translucent: true,
    class: "ion-no-border"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              ($props.canGoBack)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    shape: "round",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        "aria-label": _ctx.$t('message.back'),
                        icon: $setup.arrowBack
                      }, null, 8, ["aria-label", "icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                shape: "rect",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.goHome()))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    width: "190",
                    src: '/assets/img/thingTag_logo.svg',
                    alt: "zur Startseite"
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              ($setup.user)
                ? (_openBlock(), _createBlock(_component_user_points, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_menu_button, {
                "aria-label": _ctx.$t('message.menu')
              }, null, 8, ["aria-label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}