import { InfoText, InfoTextCategory } from "@/types";
import axios from "axios";

export default {
	async getCategorizedList(params = null) {
		const response = await axios.get(`/infotext/categorized-infotext-list`, { params: params })
		console.log("response", response)
		const infoTexts: InfoTextCategory[] = response.data
		return infoTexts
	},

	async getInfotextList(params = null) {
		const response = await axios.get(`/infotext/infotext-list`, { params: params })
		const infoTexts: InfoText[] = response.data.items
		return infoTexts
	},

	async getInfotext(id: number) {
		const response = await axios.get(`/infotext/infotext?id=${id}`)
		const infoText: InfoText = response.data
		return infoText
	},

	async getInfoTextRelatedToObject(objectId: number) {
		const response = await axios.get(`/infotext/infotext-related-to-object?objectId=${objectId}`)
		const infoTexts: InfoText = response.data
		return infoTexts
	}
}