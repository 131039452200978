import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f48cd46c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-text-start" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ion-text-start ion-padding" }
const _hoisted_5 = {
  key: 1,
  class: "ion-text-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_top_list_page_loader = _resolveComponent("top-list-page-loader")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_page_header, {
            title: _ctx.$t('message.topList')
          }, null, 8, ["title"]),
          (!_ctx.isReady)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 0,
                class: "ion-padding"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-sm": "12",
                        "size-md": "12",
                        "size-lg": "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_top_list_page_loader)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                class: "ion-padding"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                    default: _withCtx(() => [
                      (_ctx.topList.length)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "12",
                            "size-lg": "6"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('message.topList')), 1),
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  (_ctx.topList.length <= 10)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.topList, (rank, index) => {
                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                          key: index,
                                          class: _normalizeClass([{
                        myRank: parseInt(rank.user_id) === _ctx.user?.id,
                        first: parseInt(rank.rnk) === 1,
                        second: parseInt(rank.rnk) === 2,
                        third: parseInt(rank.rnk) === 3,
                      }, "ion-text-start"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                (rank.rnk > 1)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(rank.rnk) + ".", 1))
                                                  : _createCommentVNode("", true),
                                                _createTextVNode(" " + _toDisplayString(rank.name), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_label, { slot: "end" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(parseInt(rank.points)), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["class"]))
                                      }), 128))
                                    : _createCommentVNode("", true),
                                  (_ctx.topList.length > 10)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topList, (rank, index) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                            (index < 7)
                                              ? (_openBlock(), _createBlock(_component_ion_item, {
                                                  key: 0,
                                                  class: _normalizeClass({
                        myRank: parseInt(rank.user_id) === _ctx.user?.id,
                        first: parseInt(rank.rnk) === 1,
                        second: parseInt(rank.rnk) === 2,
                        third: parseInt(rank.rnk) === 3,
                      })
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_label, null, {
                                                      default: _withCtx(() => [
                                                        (index > 0)
                                                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(index + 1) + ".", 1))
                                                          : _createCommentVNode("", true),
                                                        _createTextVNode(" " + _toDisplayString(rank.name), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_ion_label, { slot: "end" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(parseInt(rank.points)), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["class"]))
                                              : _createCommentVNode("", true)
                                          ], 64))
                                        }), 128)),
                                        _createVNode(_component_ion_item, {
                                          lines: "none",
                                          class: "ion-text-center"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, { icon: _ctx.ellipsisVertical }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("message.yourPosition")), 1),
                                        (
                      _ctx.topList.find(
                        (item) => parseInt(item.user_id) === _ctx.user?.id
                      ) !== undefined
                    )
                                          ? (_openBlock(), _createBlock(_component_ion_item, {
                                              key: 0,
                                              class: _normalizeClass(["ion-text-start ion-margin-bottom", {
                      myRank:
                        parseInt(
                          _ctx.topList.find(
                            (item) => parseInt(item.user_id) === _ctx.user?.id
                          ).user_id
                        ) === _ctx.user?.id,
                    }])
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_label, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.topList.find(
                          (item) => parseInt(item.user_id) === _ctx.user?.id
                        ).rnk) + ". " + _toDisplayString(_ctx.topList.find(
                          (item) => parseInt(item.user_id) === _ctx.user?.id
                        ).name), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_label, { slot: "end" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.topList.find(
                        (item) => parseInt(item.user_id) === _ctx.user?.id
                      ).points), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["class"]))
                                          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("message.noPosition")), 1))
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "12",
                            "size-lg": "6"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("message.noRanksFound")), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_side_menu, { showGoBack: true })
    ]),
    _: 1
  }))
}