import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-172cd3b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_loader = _resolveComponent("text-loader")!
  const _component_image_loader = _resolveComponent("image-loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_text_loader, { lines: [{height: '25px', width: '30%'}, {height: '25px', width: '30%'}] }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_image_loader, { height: "100vh" })
    ])
  ], 64))
}