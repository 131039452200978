/**
 * @see https://dev.to/3vilarthas/vuex-typescript-m4j
 */
import BaseService from '@/services/BaseService';
import WorkService from '@/services/WorkService';
import InfoTextService from '@/services/InfoTextService';
import UserService from '@/services/UserService';
import { AppConfig, InfoText, Tag, User, Location, WorkObject, TaggingWork, InfoTextCategory, TaggingResults } from '@/types';
import { inject } from 'vue';
import { state, State } from './state';
import WorkObjectService from '@/services/WorkObjectService';
import { Storage } from '@capacitor/storage';

type StoreType = {
  state: State;
  getAppConfig: () => Promise<any>;
  setUser: (user: User) => Promise<any>;
  updateUser: (user: User) => Promise<any>
  getCategorizedInfotexts: () => Promise<any>;
  // getCategorizedObjects: () => Promise<any>;
  getAllLocations: () => Promise<any>;
  getAllInfoTexts: (params: any) => Promise<any>;
  getInfoText: (id: number) => Promise<any>;
  getInfoTextRelatedToObject: (objectId: number) => Promise<any>;
  saveUserTag: (tag: Tag) => Promise<any>;
  removeUserTag: (tag: Tag) => Promise<void>;

  getRandomObject: (excpetObjectId: number | null) => Promise<any>;
  getRandomTaggingWorks: (objectId: number, excpetWorksIds: number[], taggedUsages: number[]) =>  Promise<any>;
  getTaggingWork: (objectId: number, workId: number) =>  Promise<any>;

  addToResults: (result: TaggingResults) => Promise<any>;
}

export const storeSymbol = Symbol('store');

export const createStore = () => {
  const getAppConfig = async () => {
    const appConfig: AppConfig = await BaseService.getAppConfig()
    state.appConfig.value = appConfig
  }

  const setUser = async (user: User) => {
    state.user.value = user
    state.isLoggedIn.value = user ? true : false
  }

  const updateUser = async (user: User) => {
    await UserService.setUserInStorage(user)
    state.user.value = user
  }

  const getCategorizedInfotexts = async (params: any) => {
    const infotextCategories: InfoTextCategory[] = await InfoTextService.getCategorizedList(params)
    state.infotextCategories.value = infotextCategories
  }

  /*const getCategorizedObjects = async (params: any) => {
    const workObjectCategories: WorkObjectCategory[] = await WorkObjectService.getCategorizedList(params)
    state.workObjectCategories.value = workObjectCategories
  }*/

  const getAllLocations = async (params: any) => {
    const locations: Location[] = await WorkService.getLocationList(params)
    state.locations.value = locations
  }

  const getAllInfoTexts = async (params: any) => {
    const infoTexts: InfoText[] = await InfoTextService.getInfotextList(params)
    state.infoTexts.value = infoTexts
  }

  const getInfoText = async (id: number) => {
    const infoText: InfoText = await InfoTextService.getInfotext(id)
    state.infoText.value = infoText
  }

  const getInfoTextRelatedToObject = async (objectId: number) => {
    const infoText: InfoText = await InfoTextService.getInfoTextRelatedToObject(objectId)
    state.infoText.value = infoText
  }

  const saveUserTag = async (tag: Tag) => {
    const result = await UserService.saveUserTag(tag)

    if (state.user.value?.tags) {
      /*const tagIndex: number = state.user.value.tags.findIndex((item: Tag) => item?.work_id === tag?.work_id && item?.object_id === tag?.object_id)
      if (tagIndex > -1) {
        state.user.value.tags.splice(tagIndex, 1)
      }*/
      state.user.value.tags = [tag, ...state.user.value.tags]
    }
    return result
  }

  const removeUserTag = async (tag: Tag) => {
    await UserService.removeUserTag(tag)

    if (state.user.value?.tags) {
      const tagIndex: number = state.user.value.tags.findIndex((item: Tag) => item?.work_id === tag?.work_id && item?.object_id === tag?.object_id)
      state.user.value.tags.splice(tagIndex, 1)
    }
  }

  const getRandomObject = async (excpetObjectId: number | null) => {
    const workObject: WorkObject = await WorkObjectService.getRandomObject(excpetObjectId)
    state.workObject.value = workObject
  }

  const getRandomTaggingWorks = async (objectId: number, excpetWorksIds = [], taggedUsages: [] = []) => {
    const taggingWorks: TaggingWork[] = await WorkService.getRandomTaggingWorks(objectId, excpetWorksIds, taggedUsages)
    state.taggingWorks.value = taggingWorks
  }

  const getTaggingWork = async (objectId: number, workId: number) => {
    const taggingWorks: TaggingWork[] = await WorkService.getTaggingWork(objectId, workId)
    state.taggingWorks.value = taggingWorks
  }

  const addToResults = async (result: TaggingResults) => {
    const taggingIndex = state.taggingResults.value.findIndex((item) => item.work_object_usage_id === result.work_object_usage_id)

    if (taggingIndex === -1) {
      state.taggingResults.value = [result, ...state.taggingResults.value]

      await Storage.set({
        key: 'taggingResults',
        value: JSON.stringify(state.taggingResults.value)
      });
    }
  }


  return {
    state: state,
    getAppConfig,
    setUser,
    getCategorizedInfotexts,
    // getCategorizedObjects,
    getAllLocations,
    getAllInfoTexts,
    getInfoText,
    getInfoTextRelatedToObject,
    saveUserTag,
    removeUserTag,
    updateUser,
    getRandomObject,
    getRandomTaggingWorks,
    getTaggingWork,
    addToResults
  }
}

export const useStore = () => {
  return inject(storeSymbol) as StoreType
}

export type Store = StoreType
