import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_loader = _resolveComponent("item-loader")!
  const _component_card_loader = _resolveComponent("card-loader")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_item_loader, {
        bottomBorder: false,
        height: '50px',
        width: '100%'
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cards, (item) => {
        return (_openBlock(), _createBlock(_component_ion_col, {
          class: "ion-justify-content-center",
          "size-xs": "12",
          "size-sm": "6",
          "size-md": "4",
          "size-lg": "4",
          key: item
        }, {
          default: _withCtx(() => [
            _createVNode(_component_card_loader, {
              height: '300px',
              width: 'auto'
            })
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}