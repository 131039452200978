
export default {
  name: "CardLoader",
  props: {
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
