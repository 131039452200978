import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09e385d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "rewardEffectEl",
  class: "reward-effect"
}
const _hoisted_2 = { class: "inside-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_icon, {
        style: _normalizeStyle({color: $props.color}),
        icon: $setup.trophy
      }, null, 8, ["style", "icon"]),
      _createElementVNode("span", {
        style: _normalizeStyle({color: $props.color})
      }, _toDisplayString($props.rewardedPoints), 5)
    ])
  ], 512))
}