
import UserService from "@/services/UserService";
import { useStore } from "@/store";
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  onIonViewWillEnter,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import PageHeader from "../components/PageHeader.vue";
import { ellipsisVertical } from "ionicons/icons";
import TopListPageLoader from "@/components/skeleton-loaders/TopListPageLoader.vue";
import SideMenu from "../components/SideMenu.vue";

export default defineComponent({
  name: "TopListPage",
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    PageHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    TopListPageLoader,
    SideMenu,
  },
  setup() {
    const store = useStore();
    const user = store.state.user;
    const topList = ref<any>();
    const isReady = ref(false);

    onIonViewWillEnter(async () => {
      const userId = user.value?.id;
      topList.value = await UserService.topList(userId ? userId : null);
      isReady.value = true;
    });

    return {
      user,
      topList,
      isReady,
      ellipsisVertical,
    };
  },
});
