import { Institution, Work, Location, TaggingWork } from "@/types";
import { Storage } from '@capacitor/storage';
import axios from "axios";

export function hashCode(str: string): number {
	let h = 0;
	for (let i = 0; i < str.length; i++) {
		h = 31 * h + str.charCodeAt(i);
	}
	return h & 0xFFFFFFFF
}

export default {
	async getWork(id: number) {
		const response = await axios.get(`/work/work?id=${id}`)
		const work: Work = response.data

		return work
	},
	async getInstitutions(location: string) {
		const institutions = await axios.get(`/work/institutions?location=${location}`)
			.then((response) => {
				const NewLocation: Location = {
					name: location,
					institutions: response.data
				}
				this.getFromStorage('locations')
					.then((StorageLocations) => {
						const LocationIndex: number = StorageLocations.findIndex((item: Location) => item?.name === NewLocation?.name)
						if (LocationIndex > -1) {
							StorageLocations.splice(LocationIndex, 1, NewLocation)
						}
						this.setInStorage('locations', StorageLocations)
					})
					.catch(error => {
						console.log("Noch keine Storage Locations! " + error)
						this.getLocationList()
					})
				return NewLocation
			})
			.catch(error => {
				console.log("Konnte Institutions nicht abfragen! " + error)
			})
		return institutions
	},
	async getInstitution(institution: string, location: string) {
		const myinstitution = await axios.get(`/work/institution?institution=${institution}&location=${location}`)
			.then((response) => {
				console.log('RESPONSE Inst', response.data)
				const NewInstitution: Institution[] = response.data[0]
				this.setInStorage('institution_' + hashCode(institution + location), NewInstitution)
				return NewInstitution
			})
			.catch(error => {
				console.log(error)
				return this.getFromStorage('institution_' + hashCode(institution + location))
			})
		return myinstitution
	},
	async getLocationList(params = null) {
		const locations: Location[] = await axios.get(`/work/locations`, { params: params })
			.then((response) => {
				const locations: Location[] = []
				response.data.forEach(function (location: string) {
					locations.push({
						name: location,
					})
				})
				this.setInStorage('locations', locations)
				return locations
			})
			.catch(error => {
				console.log(error)
				return this.getFromStorage('locations')
			})
		return locations
	},
	async getFromStorage(key: string) {
		const { value } = await Storage.get({ key: key })
		if (value)
			return JSON.parse(value)
		else
			return null
	},
	async setInStorage(key: string, value: any) {
		Storage.set({
			key: key,
			value: JSON.stringify(value),
		});
	},

	async getRandomTaggingWorks(objectId: number, excpetWorksIds = [], taggedUsages: [] = []) {
		const response = await axios.get(`/work/random-tagging-works?objectId=${objectId}`, { params: {excpetWorksIds: JSON.stringify(excpetWorksIds), taggedUsages: JSON.stringify(taggedUsages)}})
		const works: TaggingWork[] = response.data
		return works
	},

	async getTaggingWork(objectId: number, workId: number) {
		const response = await axios.get(`/work/tagging-work?objectId=${objectId}&workId=${workId}`)
		const works: TaggingWork[] = response.data
		return works
	},
}