
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import PageHeader from "../components/PageHeader.vue";

export default defineComponent({
  name: "NotFoundPage",
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
  },
  setup() {
    return {};
  },
});
