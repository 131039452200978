
import UserService from '@/services/UserService';
import { useStore } from '@/store';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner, IonModal, IonHeader, IonToolbar, IonButtons, IonIcon, IonTitle, IonText, onIonViewWillEnter } from '@ionic/vue';
import { defineComponent, reactive, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '../../components/PageHeader.vue'
import { useVuelidate } from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import { close } from 'ionicons/icons';


export default defineComponent({
  name: 'LoginPage',
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid, IonRow, IonCol, IonButton, IonLabel, IonItem, IonInput, IonSpinner, IonModal,
    IonHeader, IonToolbar, IonButtons, IonIcon, IonTitle, IonText
  }, 
  setup () {
    const store = useStore()
    const appConfig = store.state.appConfig
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const isLoading = ref(false)
    const isPasswordResetModalPresented = ref(false)
    const setPasswordResetModalPresented = (state: boolean) => isPasswordResetModalPresented.value = state;
    const passwordModelDidDismiss = () => setPasswordResetModalPresented(false)
    const isResendConfirmationModalPresented = ref(false)
    const setResendConfirmationModalPresented = (state: boolean) => isResendConfirmationModalPresented.value = state  
    const resendConfirmationModelDidDismiss = () => setResendConfirmationModalPresented(false)

    onIonViewWillEnter(() => {
      UserService.isLoggedIn().then((value: boolean) => {
        if(value) {
          router.push('/')
        }
      })
    })
    // login form
    const loginForm = reactive({
      username: '',
      password: ''
    })
    const loginFormRules = {
      username: { required },
      password: { required }
    }
    const validateLoginForm = useVuelidate(loginFormRules, {
      username: toRef(loginForm, 'username'),
      password: toRef(loginForm, 'password')
    });

    const lightRegisterFrom = reactive({
      name: ''
    })

    const lightRegisterFromRules = {
      name: { required }
    }

    const validateLightRegisterFrom = useVuelidate(lightRegisterFromRules, {
      name: toRef(lightRegisterFrom, 'name')
    })

    // reset password form
    const resetPasswordForm = reactive({
      email: ''
    })
    const resetPasswordRules = {
      email: { required, email }
    }
    const validateResetPassword= useVuelidate(resetPasswordRules, {
      email: toRef(resetPasswordForm, 'email')
    })

    // resend confirmation link form
    const resendConfirmationForm = reactive({
      email: ''
    })

    const resendConfirmationFormRules = {
      email: { required, email }
    }

    const validateResendConfirmationForm= useVuelidate(resendConfirmationFormRules, {
      email: toRef(resendConfirmationForm, 'email')
    })

    // handle the submit of the form, only called
    // if the form is valid
    const login = async () => {
      validateLoginForm.value.$touch();
      if (validateLoginForm.value.$invalid) return

      isLoading.value = true
      UserService.login(loginForm.username, loginForm.password).then(async (user) => {
        await UserService.setUserInStorage(user)
        await store.setUser(user)
        isLoading.value = false
        const redirectPath = route.query.redirect
        redirectPath ? router.push(redirectPath.toString()) : router.push('/')
      }).catch(() => {
        isLoading.value = false
      })
    }

    const onSubmitLight = async () => {
      validateLightRegisterFrom.value.$touch();
      if (validateLightRegisterFrom.value.$invalid) return

      isLoading.value = true
       UserService.lightRegister(lightRegisterFrom.name).then( async (user) => {
        await UserService.setUserInStorage(user)
        await store.setUser(user)
        isLoading.value = false
        const redirectPath = route.query.redirect
        redirectPath ? router.push(redirectPath.toString()) : router.push('/')
       }).catch(() => {
        isLoading.value = false
      })
    }

    const isSending = ref(false)
    const isEmailSent = ref(false)
    const resetPassword = async () => {
      validateResetPassword.value.$touch();
      if (validateResetPassword.value.$invalid) return

      isSending.value = true
      UserService.sendResetpasswordLink(resetPasswordForm.email).then(async (value) => {
        if (value) {
          isEmailSent.value = true
        }
        isSending.value = false
      }).catch(() => {
        isSending.value = false
      })
    }

    const isSendingConfirmationLink = ref(false)
    const isConfimationLinkSent = ref(false)
    const resendConfirmationLink = async () => {
      validateResendConfirmationForm.value.$touch();
      if (validateResendConfirmationForm.value.$invalid) return

      isSendingConfirmationLink.value = true
      UserService.resendConfirmationLink(resendConfirmationForm.email).then(async (value) => {
        if (value) {
          isConfimationLinkSent.value = true
        }
        isSendingConfirmationLink.value = false
      }).catch(() => {
        isSendingConfirmationLink.value = false
      })
    }

    return {
      t,
      login,
      validateLoginForm,
      isLoading,
      isPasswordResetModalPresented,
      setPasswordResetModalPresented,
      close,
      validateResetPassword,
      resetPassword,
      passwordModelDidDismiss,
      isSending,
      isEmailSent,
      resendConfirmationModelDidDismiss,
      setResendConfirmationModalPresented,
      isResendConfirmationModalPresented,
      validateResendConfirmationForm,
      isSendingConfirmationLink,
      isConfimationLinkSent,
      resendConfirmationLink,
      router,
      validateLightRegisterFrom,
      onSubmitLight,
      appConfig
    }
  }
});
