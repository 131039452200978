
import ButtonLoader from './ButtonLoader.vue';
import ImageLoader from './ImageLoader.vue'
import TextLoader from './TextLoader.vue';
export default {
  name: "WorkPageLoader",
  components: {
    ImageLoader,
    ButtonLoader,
    TextLoader
  },
  setup() {
    return {};
  },
};
