import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "points",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.router.push('/top-list')))
  }, [
    _createVNode(_component_ion_icon, {
      slot: "icon-only",
      icon: $setup.trophy
    }, null, 8, ["icon"]),
    _createElementVNode("span", null, _toDisplayString($setup.getPoints), 1)
  ]))
}