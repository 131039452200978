
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import SideMenu from "../components/SideMenu.vue";
import PageHeader from "../components/PageHeader.vue";

export default defineComponent({
  name: "ImpressumPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    SideMenu,
    PageHeader
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  },
});
