
export default {
  name: "ImageLoader",
  props: {
    height: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
