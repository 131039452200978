
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonIcon
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import PageHeader from "../components/PageHeader.vue";
import SideMenu from "../components/SideMenu.vue";
import InfoTextService from "@/services/InfoTextService";
import MultiItemListLoader from "@/components/skeleton-loaders/MultiItemListLoader.vue";
import InfotextDescriptionPage from "@/components/skeleton-loaders/InfotextDescriptionPage.vue";
import { InfoText } from "@/types";
import LongText from "@/components/LongText.vue";
import { arrowForwardOutline } from "ionicons/icons";

export default defineComponent({
  name: "ExplorePage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    PageHeader,
    SideMenu,
    MultiItemListLoader,
    InfotextDescriptionPage,
    LongText,
    IonIcon
},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isReady = ref(false);
    const categories = store.state.infotextCategories;
    const infotext = ref<InfoText>();
    const infotextId = ref(0);
    const workObjectId = ref(0)

    onMounted(async () => {
      if (route.params.id) {
        infotextId.value = parseInt(route.params.id.toString());
        await store.getCategorizedInfotexts();

        infotext.value = await InfoTextService.getInfotext(infotextId.value);
        const objectId = getWorkObjectId(infotextId.value)
        if (objectId) {
          workObjectId.value = objectId
        }
      } else {
        await store.getCategorizedInfotexts();
      }

      isReady.value = true;
    });

    const getWorkObjectId = (infotextId: number) => {
      for (const category of categories.value) {
        const infotext = category?.infotexts.find((item) => item.id === parseInt(infotextId.toString()))
        if (typeof infotext !== 'undefined') {
          return infotext.work_object_id
        }
      }
    }

    const goTagging = (workObjectId: number | undefined) => {
      store.state.showInfoText.value = false
      router.push('/tagging/' + workObjectId)
    }

    return {
      isReady,
      categories,
      infotext,
      router,
      route,
      infotextId,
      workObjectId,
      goTagging,
      arrowForwardOutline
    };
  },
});
