import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70474df8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "head" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = { class: "head" }
const _hoisted_4 = { class: "items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_loader = _resolveComponent("item-loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px",
        bottomBorder: false
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px",
        bottomBorder: false
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      }),
      _createVNode(_component_item_loader, {
        width: "100%",
        height: "50px"
      })
    ])
  ], 64))
}