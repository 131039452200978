
import ItemLoader from './ItemLoader.vue';
import TextLoader from './TextLoader.vue';
export default {
  name: "TopListPageLoader",
  components: {
    TextLoader,
    ItemLoader
  },
  setup() {
    return {};
  },
};
