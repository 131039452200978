
import { useStore } from "@/store";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import PageHeader from "../components/PageHeader.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonPage,
    PageHeader,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const store = useStore();
    const user = store.state.user;
    const router = useRouter();

    return {
      user,
      router,
    };
  },
});
